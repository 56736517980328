import React from "react"
import _ from "underscore"
import he from "he"
import moment from "moment"
import "moment/locale/de";
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Img from "gatsby-image"
import BackLink from "../components/utils/backlink"
import IconText from "../components/utils/icontext"
import Avatar from "../components/utils/avatar"
import Gallery from "../components/utils/gallery"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class StoreTemplate extends React.Component {
    render() {
        const { data } = this.props.pageContext
        const { featured_media, acf, brand_nodes, product_nodes } = data
        let directionsLink = false

        const address = [
            acf.address,
            `${acf.zip} ${acf.city}`
        ]
        
        if (acf.address && acf.zip && acf.city)
            directionsLink = `https://www.google.com/maps/dir/?api=1&destination=` 
                + encodeURIComponent(`${acf.address}, ${acf.zip} ${acf.city}`)

        return (
            <Layout mainClassName="pb-0">
                {data &&
                <>

                <SEO title={ he.decode(data.title) } description={ he.decode(acf.excerpt) } />
                <article className="py-sm bg-white rounded-t-xl pb-16 mt-sm relative z-10 min-h-screen">
                    <div className="container narrow">
                        <div className="pt-2 mb-8 text-base pb-4">
                            <Avatar className="mb-2 w-24 mx-auto">
                                {featured_media && featured_media.localFile && featured_media.localFile.childImageSharp &&
                                    <Img
                                        fixed={featured_media.localFile.childImageSharp.fixed}
                                        alt={data.title}
                                        />
                                }
                            </Avatar>

                            <BackLink to="/stores" />
                            <h1 className="mt-2 mb-4" dangerouslySetInnerHTML={{ __html: data.title }} />
                            {acf.description &&
                                <div className="font-bold text-gray -mt-4 mb-4">{acf.description}</div>
                            }
                            {acf.excerpt &&
                                <p className="text-base">{acf.excerpt}</p>
                            }

                            {directionsLink &&
                                <a href={directionsLink} target="_blank" rel="noopener noreferrer" className="bg-primary rounded py-3 leading-tight px-sm relative border-none inline-block mb-xs text-white">
                                    <FontAwesomeIcon icon={["fal", "map"]} className="mr-2" />
                                    Route mit Google Maps
                                    <FontAwesomeIcon icon={["fal", "external-link"]} className="ml-2" />
                                </a>
                            }

                            <IconText icon="map-marker" className="mt-8">
                                <div dangerouslySetInnerHTML={{ __html: _.reject(address, _.isEmpty).join("<br />") }} />
                            </IconText>

                            {acf.phone &&
                                <IconText icon="phone" className="-mt-2">
                                    <a href={`tel:${acf.phone.replace(/[^0-9+]/g, "")}`}>{acf.phone}</a>
                                </IconText>
                            }

                            {acf.email &&
                                <IconText icon="envelope" className="-mt-2">
                                    <a href={`mailto:${acf.email}`}>{acf.email}</a>
                                </IconText>
                            }

                            {acf.website &&
                                <IconText icon="link" className="-mt-4">
                                    <a href={`${acf.website}`} target="_blank" rel="noopener noreferrer">{acf.website.replace(/(^\w+:|^)\/\//, '').replace(/^www\./, '')}</a>
                                </IconText>
                            }

                            {!acf.no_openings && acf.opening_hours &&
                                <IconText icon="clock" className="my-8">
                                    <table>
                                        <tbody>
                                        {Object.keys(acf.opening_hours).map((key, index) => {
                                            const day = acf.opening_hours[key]
                                            const dayName = moment().weekday(index).format("ddd")

                                            if (!day.opened) {
                                                return (
                                                    <tr key={key}>
                                                        <th>{dayName}</th>
                                                        <td>geschlossen</td>
                                                    </tr>
                                                )
                                            }

                                            if (day.time.length === 1 && !day.time[0].from && !day.time[0].to) {
                                                return (
                                                    <tr key={key}>
                                                        <th>{dayName}</th>
                                                        <td>geöffnet</td>
                                                    </tr>
                                                )
                                            }

                                            return day.time.map((opened, i) => {
                                                return (
                                                    <tr key={`${key}-${i}`}>
                                                        {i === 0 && <th className="pr-2" rowSpan={day.time.length}>{dayName}</th>}
                                                        <td>{`${opened.from} – ${opened.to}`} Uhr</td>
                                                    </tr>
                                                )
                                            })
                                        })}
                                        </tbody>
                                    </table>
                                </IconText>
                            }

                            {((product_nodes && product_nodes.length > 0) || (brand_nodes && brand_nodes.length > 0)) &&
                                <IconText icon="info-circle">
                                    {product_nodes && product_nodes.length > 0 &&
                                        <div className="mb-4">
                                            <h3 className="my-0 text-base">Produkte & Dienstleistungen</h3>
                                            <div>
                                                {product_nodes.map(product => product.name).join(', ')}
                                            </div>
                                        </div>
                                    }

                                    {brand_nodes && brand_nodes.length > 0 &&
                                        <div className="mb-4">
                                            <h3 className="my-0 text-base">Marken</h3>
                                            <div>
                                                {brand_nodes.map(brand => brand.name).join(', ')}
                                            </div>
                                        </div>
                                    }
                                </IconText>
                            }
                        </div>
                        <div className="article" dangerouslySetInnerHTML={{ __html: data.content }} />
                    </div>
                    {acf.pictures &&
                        <Gallery pictures={acf.pictures} container={true} />
                    }
                </article>

                </>
                }
            </Layout>
        )
    }
}

export default StoreTemplate
