import React from "react"

export default ({ children, className }) => (
    <div className={['max-w-24 mx-auto avatar', className].join(' ')}>
        <figure className="block overflow-hidden h-0 pt-full bg-gray-100 rounded-full relative">
            <div className="leading-none absolute top-0 left-0 w-full h-full">
                {children}
            </div>
        </figure>
    </div>
)
